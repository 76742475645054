//
// Copyright 2018 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@import "@material/theme/functions";
@import "@material/theme/variables"; // for mdc-theme-prop-value() function

$mdc-snackbar-fill-color: mix(mdc-theme-prop-value(on-surface), mdc-theme-prop-value(surface), 80%) !default;
$mdc-snackbar-label-ink-color: rgba(mdc-theme-prop-value(surface), mdc-theme-text-emphasis(high)) !default;
$mdc-snackbar-action-ink-color: #bb86fc !default;
$mdc-snackbar-dismiss-ink-color: rgba(mdc-theme-prop-value(surface), mdc-theme-text-emphasis(high)) !default;

$mdc-snackbar-label-type-scale: body2 !default;
$mdc-snackbar-dismiss-icon-size: 18px !default;
$mdc-snackbar-min-width: 344px !default;
$mdc-snackbar-max-width: 672px !default;
$mdc-snackbar-mobile-breakpoint: 480px !default;
$mdc-snackbar-viewport-margin-narrow: 8px !default;
$mdc-snackbar-viewport-margin-wide: 24px !default;
$mdc-snackbar-padding: 8px !default;

$mdc-snackbar-elevation: 6 !default;
$mdc-snackbar-shape-radius: small !default; // Key from $mdc-shape-category-values or CSS length value (e.g., 4px)
$mdc-snackbar-z-index: 8 !default; // One above mdc-dialog

// These variables need to be kept in sync with the values in constants.js.
$mdc-snackbar-enter-duration: 150ms !default;
$mdc-snackbar-exit-duration: 75ms !default;
