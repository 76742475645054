//
// Copyright 2019 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@import "@material/theme/functions";

$mdc-data-table-fill-color: surface !default;
$mdc-data-table-header-row-fill-color: inherit !default;
$mdc-data-table-row-fill-color: inherit !default;
$mdc-data-table-selected-row-fill-color: rgba(mdc-theme-prop-value(primary), .04) !default;

$mdc-data-table-checked-icon-color: primary !default;
$mdc-data-table-divider-color: rgba(mdc-theme-prop-value(on-surface), .12) !default;
$mdc-data-table-divider-size: 1px !default;
$mdc-data-table-row-hover-fill-color: rgba(mdc-theme-prop-value(on-surface), .04) !default;
$mdc-data-table-checkbox-touch-dimension: 48px !default;

$mdc-data-table-header-row-text-color: rgba(mdc-theme-prop-value(on-surface), .87) !default;
$mdc-data-table-row-text-color: rgba(mdc-theme-prop-value(on-surface), .87) !default;

$mdc-data-table-shape-radius: medium !default;
$mdc-data-table-stroke-size: 1px !default;
$mdc-data-table-stroke-color: rgba(mdc-theme-prop-value(on-surface), .12) !default;

$mdc-data-table-row-height: 52px !default;
$mdc-data-table-header-row-height: $mdc-data-table-row-height + 4px !default;
$mdc-data-table-cell-leading-padding: 16px !default;
$mdc-data-table-cell-trailing-padding: 16px !default;
