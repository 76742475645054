//
// Copyright 2017 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@import "@material/button/mdc-button";
@import "@material/card/mdc-card";
@import "@material/checkbox/mdc-checkbox";
@import "@material/chips/mdc-chips";
@import "@material/data-table/mdc-data-table";
@import "@material/dialog/mdc-dialog";
@import "@material/drawer/mdc-drawer";
@import "@material/elevation/mdc-elevation";
@import "@material/fab/mdc-fab";
@import "@material/floating-label/mdc-floating-label";
@import "@material/form-field/mdc-form-field";
@import "@material/grid-list/mdc-grid-list";
@import "@material/icon-button/mdc-icon-button";
@import "@material/image-list/mdc-image-list";
@import "@material/layout-grid/mdc-layout-grid";
@import "@material/line-ripple/mdc-line-ripple";
@import "@material/linear-progress/mdc-linear-progress";
@import "@material/list/mdc-list";
@import "@material/menu/mdc-menu";
@import "@material/menu-surface/mdc-menu-surface";
@import "@material/notched-outline/mdc-notched-outline";
@import "@material/radio/mdc-radio";
@import "@material/ripple/mdc-ripple";
@import "@material/select/mdc-select";
@import "@material/slider/mdc-slider";
@import "@material/snackbar/mdc-snackbar";
@import "@material/switch/mdc-switch";
@import "@material/tab/mdc-tab";
@import "@material/tab-bar/mdc-tab-bar";
@import "@material/tab-indicator/mdc-tab-indicator";
@import "@material/tab-scroller/mdc-tab-scroller";
@import "@material/textfield/mdc-text-field";
@import "@material/theme/mdc-theme";
@import "@material/top-app-bar/mdc-top-app-bar";
@import "@material/typography/mdc-typography";
